<template>
  <div id="app">
    <Home />
  </div>
</template>

<script>
import Home from "@/components/Home";

export default {
  name: "App",
  components: {
    Home,
  },
};
</script>

<style>
body {
  background-image: url("/illustration.jpg");
  background-size: cover;
}
.ant-layout {
  background: unset !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.ant-calendar-picker{
  width: 100%;
}
</style>
