import Vue from 'vue';
import Vuex from 'vuex';
import home from './modules/home';
import detect from './modules/detect';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        home,
        detect,
    },
});