import Login from "@/components/Login";
import AuthInfo from "@/components/AuthInfo";
import AuthProfile from "@/components/AuthProfile";

const state = {
    steps: [
        {
            icon: "mail",
            name: "login",
            title: "Điền thông tin",
            subtitle: "Điền các thông tin cá nhân",
            component: Login,
            completed: false,
        },
        {
            icon: "person",
            name: "authInfo",
            title: "Xác thực thông tin cá nhân",
            subtitle: "Qua CCCD hoặc CMND",
            component: AuthInfo,
            completed: false,
        },
        {
            icon: "tag_faces",
            name: "authProfile",
            title: "Xác thực ảnh thẻ",
            subtitle: "So khớp ảnh thẻ và CCCD hoặc CMND",
            component: AuthProfile,
            completed: false,
        },
    ]
};

// Getters
const getters = {};

// Actions
const actions = {
    completeStep(context, payload) {
        context.commit('COMPLETE_STEP', payload);
    },
    isStepActive(context, payload) {
        context.commit('IS_STEP_ACTIVE', payload);
    },
};

// Mutation
const mutations = {
    COMPLETE_STEP: (state, data) => {
        state.steps.forEach((step) => {
            if (step.name === data.name) {
                step.completed = true;
            }
        });
    },
    IS_STEP_ACTIVE: (state, data) => {
        state.steps.forEach((step) => {
            if (step.name === data.name) {
                if (step.completed === true) {
                    step.completed = false;
                }
            }
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};