<template>
  <a-spin tip="Đang xử lý..." :spinning="loading">
    <a-upload accept="image/*" class="avatar-uploader mt-4" list-type="picture-card" style="width: unset" :show-upload-list="false" @change="handleChange" action="https://verify.neu.edu.vn/match-student2" :data="body" name="id_card">
      <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
      <div v-else>
        <a-icon type="plus" />
        <div class="ant-upload-text">Chọn ảnh CCCD hoặc CMND</div>
      </div>
    </a-upload>
    <div :class="textClass" v-if="textClass">{{ message }}</div>
  </a-spin>
</template>

<script>
import {getBase64} from "@/utils/imageUtils";
import {mapState, mapActions} from "vuex";
export default {
  name: "AuthInfo",
  data() {
    return {
      imageUrl: "",
      loading: false,
      message: "",
      textClass: "",
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.detect.userInfo,
    }),
    body() {
      return this.userInfo;
    },
  },
  methods: {
    ...mapActions({
      setIdCard: "detect/setIdCard",
      setInfo: "detect/setInfo",
    }),
    async handleChange(info) {
      this.$emit("can-continue", {value: true});
      if (info.file.status == "done") {
        this.loading = false;
        this.textClass = "text-success";
        this.message = "Thông tin chính xác";
        this.setIdCard(info.file.originFileObj);
        this.setInfo(info.file.response);
        this.$emit("can-continue", {value: true});
      } else if (info.file.status === "uploading") {
        this.textClass = "";
        this.message = "";
        this.loading = true;
        getBase64(info.file.originFileObj, imageUrl => {
          this.imageUrl = imageUrl;
        });
      } else if (info.file.status === "error") {
        this.loading = false;
        this.textClass = "text-danger";
        this.$emit("can-continue", {value: false});
        this.message = info.file.response.message;
      }
    },
  },
};
</script>
