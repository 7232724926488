<template>
  <a-spin tip="Đang xử lý..." :spinning="loading">
    <a-upload
      accept="image/*"
      class="avatar-uploader mt-4"
      list-type="picture-card"
      style="width: unset"
      :show-upload-list="false"
      @change="handleChange"
      action="https://verify.neu.edu.vn/match-face2"
      :data="body"
      name="profile"
    >
      <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
      <div v-else>
        <a-icon type="plus" />
        <div class="ant-upload-text">Chọn ảnh khuôn mặt</div>
      </div>
    </a-upload>
    <div :class="textClass" v-if="textClass">{{ message }}</div>
  </a-spin>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { getBase64 } from "@/utils/imageUtils";
export default {
  name: "AuthProfile",
  data() {
    return {
      imageUrl: "",
      loading: false,
      message: "",
      textClass: "",
    };
  },
  computed: {
    ...mapState({
      id_card: (state) => state.detect.id_card,
      userInfo: (state) => state.detect.userInfo,
    }),
    body() {
      return {
        id_card: this.id_card,
        ...this.userInfo,
      };
    },
  },
  methods: {
    ...mapActions({
      setFace: "detect/setFace",
    }),
    async handleChange(info) {
      if (info.file.status == "done") {
        this.loading = false;
        this.textClass = "text-success";
        this.message = info.file.response.message;
        this.setFace(info.file.response.face);
        this.$emit("can-continue", { value: true });
      } else if (info.file.status === "uploading") {
        this.textClass = "";
        this.message = "";
        this.loading = true;
        getBase64(info.file.originFileObj, (imageUrl) => {
          this.imageUrl = imageUrl;
        });
      } else if (info.file.status === "error") {
        this.loading = false;
        this.textClass = "text-danger";
        this.message =
          "Ảnh khuôn mặt không chính xác. Vui lòng chọn đúng ảnh khuôn mặt của bạn.";
        this.$emit("can-continue", { value: false });
      }
    },
  },
};
</script>
