import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Button from 'ant-design-vue/lib/button';
import Layout from 'ant-design-vue/lib/layout';
import Upload from 'ant-design-vue/lib/upload';
import Icon from 'ant-design-vue/lib/icon';
import Spin from 'ant-design-vue/lib/spin';
import Form from 'ant-design-vue/lib/form';
import FormItem from 'ant-design-vue/lib/form/FormItem';
import Input from 'ant-design-vue/lib/input';
import DatePicker from 'ant-design-vue/lib/date-picker';
import Vuex from 'vuex';
import store from '@/store';
import "@/css/main.css";
import "@/css/spacer.css";
import "@/css/text.css";
import "ant-design-vue/dist/antd.css";

Vue.use(Vuex)
Vue.use(VueAxios, axios)
Vue.component(Layout.name, Layout);
Vue.component(Layout.Content.name, Layout.Content);
Vue.component(Button.name, Button);
Vue.component(Upload.name, Upload);
Vue.component(Icon.name, Icon);
Vue.component(Spin.name, Spin);
Vue.component(Form.name, Form);
Vue.component(FormItem.name, FormItem);
Vue.component(Input.name, Input);
Vue.component(DatePicker.name, DatePicker);

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  store,
}).$mount('#app')
