<template>
  <a-layout>
    <a-layout-content>
      <div class="text-center mt-5">
        <img class="mt-5 logo" src="logo.png" />
        <h1 class="text-white">Hệ thống cấp lại mật khẩu NEU</h1>
      </div>
      <horizontal-stepper class="mt-5" :steps="steps" locale="vi" @completed-step="completeStep" @active-step="isStepActive" top-buttons :keep-alive="false"></horizontal-stepper>
      <img class="image" v-for="(image, i) in items" :src="image" @click="currentId = i" :key="i" />
      <vue-gallery-slideshow :images="items" :index="currentId" @close="currentId = null"></vue-gallery-slideshow>
      <div class="mt-2"></div>
    </a-layout-content>
  </a-layout>
</template>

<script>
import HorizontalStepper from "vue-stepper-pro";
import VueGallerySlideshow from "vue-gallery-slideshow";
import {mapState, mapActions} from "vuex";

export default {
  name: "Home",
  components: {
    HorizontalStepper,
    VueGallerySlideshow,
  },
  data() {
    return {
      items: [],
      currentId: null,
    };
  },
  mounted() {
    for (let index = 7; index <= 7; index++) {
      this.items.push(index + ".jpg");
    }
  },
  computed: {
    ...mapState({
      steps: state => state.home.steps,
    }),
  },
  methods: {
    ...mapActions({
      completeStep: "home/completeStep",
      isStepActive: "home/isStepActive",
    }),
  },
};
</script>
<style>
@media (min-width: 600px) {
  .ant-layout-content {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
}
.image {
  height: 200px;
  cursor: pointer;
  margin-top: 15px;
  margin-right: 5px;
  border-radius: 5px;
  border: 2px solid white;
}
</style>
