import moment from "moment";
const state = {
  id_card: null,
  face: null,
  info: null,
  userInfo: {
    mssv: "",
    name: "",
    birthday: moment("2002-01-01", "YYYY-MM-DD"),
    email: "",
    phone: "",
  },
};

// Getters
const getters = {};

// Actions
const actions = {
  setIdCard(context, payload) {
    context.commit("SET_ID_CARD", payload);
  },
  setFace(context, payload) {
    context.commit("SET_FACE", payload);
  },
  setInfo(context, payload) {
    context.commit("SET_INFO", payload);
  },
  setUserInfo(context, payload) {
    console.log(payload);
    context.commit("SET_USER_INFO", payload);
  },
};

// Mutation
const mutations = {
  SET_ID_CARD: (state, data) => {
    state.id_card = data;
  },
  SET_FACE: (state, data) => {
    state.face = data;
  },
  SET_INFO: (state, data) => {
    state.info = data;
  },
  SET_USER_INFO: (state, data) => {
    state.userInfo = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
