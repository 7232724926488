<template>
  <div class="mt-4 ml-4 mr-4">
    <a-form :form="form">
      <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" label="MSSV" @change="check()">
        <a-input v-decorator="['mssv', {rules: [{required: true, message: 'Vui lòng nhập MSSV'}]}]" placeholder="Mã số sinh viên" :initialValue="userInfo.mssv" @keyup="check()" />
      </a-form-item>
      <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" label="Họ và tên">
        <a-input v-decorator="['name', {rules: [{required: true, message: 'Vui lòng nhập Họ và tên'}]}]" placeholder="Họ và tên" :initialValue="userInfo.name" @keyup="check()" />
      </a-form-item>
      <a-form-item label="Ngày sinh" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
        <a-date-picker
          :locale="locale"
          :defaultPickerValue="userInfo.birthday"
          format="DD-MM-YYYY"
          v-decorator="[
            'birthday',
            {
              rules: [
                {
                  type: 'object',
                  required: true,
                  message: 'Vui lòng chọn ngày sinh',
                },
              ],
            },
          ]"
          @keyup="check()"
        />
      </a-form-item>
      <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" label="Email phụ (Gmail)">
        <a-input
          v-decorator="[
            'email',
            {
              rules: [{required: true, message: 'Vui lòng nhập email phụ (Gmail)'}],
            },
          ]"
          placeholder="Địa chỉ email phụ (Gmail)"
          :initialValue="userInfo.email"
          @keyup="check()"
        />
      </a-form-item>
      <a-form-item :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" label="Số điện thoại">
        <a-input
          v-decorator="[
            'phone',
            {
              rules: [
                {
                  len: 10,
                  pattern: '[0-9]{10}',
                  message: 'Số điện thoại phải gồm 10 chữ số',
                },
                {required: true, message: 'Vui lòng nhập số điện thoại'},
              ],
            },
          ]"
          placeholder="Số điện thoại"
          :initialValue="userInfo.phone"
          @keyup="check()"
        />
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
const formItemLayout = {
  labelCol: {span: 4, offset: 4},
  wrapperCol: {span: 8},
};

import {mapState, mapActions} from "vuex";
import locale from "ant-design-vue/lib/date-picker/locale/vi_VN";
export default {
  name: "Login",
  data() {
    return {
      formItemLayout,
      form: this.$form.createForm(this, {name: "dynamic_rule"}),
      locale,
    };
  },
  created() {
    self = this;
    this.form = this.$form.createForm(this, {
      name: "global_state",
      onFieldsChange: (_, changedFields) => {
        this.$emit("change", changedFields);
      },
      onValuesChange(_, values) {
        let userInfo = _.form.getFieldsValue();
        for (const [key, value] of Object.entries(values)) {
          userInfo[key] = value;
          userInfo = {
            ...userInfo,
            key: value,
          };
        }

        if (userInfo.mssv && userInfo.name && userInfo.birthday && userInfo.email && userInfo.phone && userInfo.phone.length == 10) {
          self.$emit("can-continue", {value: true});
        }
      },
    });
  },
  computed: {
    ...mapState({
      userInfo: state => state.detect.userInfo,
    }),
  },
  methods: {
    ...mapActions({
      completeStep: "home/completeStep",
      setUserInfo: "detect/setUserInfo",
    }),
    check() {
      this.form.validateFields((err, fieldsValue) => {
        if (!err) {
          fieldsValue.birthday = fieldsValue.birthday.format("DD-MM-YYYY");
          this.setUserInfo(fieldsValue);
          this.$emit("can-continue", {value: true});
        } else {
          console.log(err);
        }
      });
    },
  },
};
</script>
