var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4 ml-4 mr-4"},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"MSSV"},on:{"change":function($event){return _vm.check()}}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['mssv', {rules: [{required: true, message: 'Vui lòng nhập MSSV'}]}]),expression:"['mssv', {rules: [{required: true, message: 'Vui lòng nhập MSSV'}]}]"}],attrs:{"placeholder":"Mã số sinh viên","initialValue":_vm.userInfo.mssv},on:{"keyup":function($event){return _vm.check()}}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"Họ và tên"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {rules: [{required: true, message: 'Vui lòng nhập Họ và tên'}]}]),expression:"['name', {rules: [{required: true, message: 'Vui lòng nhập Họ và tên'}]}]"}],attrs:{"placeholder":"Họ và tên","initialValue":_vm.userInfo.name},on:{"keyup":function($event){return _vm.check()}}})],1),_c('a-form-item',{attrs:{"label":"Ngày sinh","label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'birthday',
          {
            rules: [
              {
                type: 'object',
                required: true,
                message: 'Vui lòng chọn ngày sinh',
              } ],
          } ]),expression:"[\n          'birthday',\n          {\n            rules: [\n              {\n                type: 'object',\n                required: true,\n                message: 'Vui lòng chọn ngày sinh',\n              },\n            ],\n          },\n        ]"}],attrs:{"locale":_vm.locale,"defaultPickerValue":_vm.userInfo.birthday,"format":"DD-MM-YYYY"},on:{"keyup":function($event){return _vm.check()}}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"Email phụ (Gmail)"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'email',
          {
            rules: [{required: true, message: 'Vui lòng nhập email phụ (Gmail)'}],
          } ]),expression:"[\n          'email',\n          {\n            rules: [{required: true, message: 'Vui lòng nhập email phụ (Gmail)'}],\n          },\n        ]"}],attrs:{"placeholder":"Địa chỉ email phụ (Gmail)","initialValue":_vm.userInfo.email},on:{"keyup":function($event){return _vm.check()}}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"Số điện thoại"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'phone',
          {
            rules: [
              {
                len: 10,
                pattern: '[0-9]{10}',
                message: 'Số điện thoại phải gồm 10 chữ số',
              },
              {required: true, message: 'Vui lòng nhập số điện thoại'} ],
          } ]),expression:"[\n          'phone',\n          {\n            rules: [\n              {\n                len: 10,\n                pattern: '[0-9]{10}',\n                message: 'Số điện thoại phải gồm 10 chữ số',\n              },\n              {required: true, message: 'Vui lòng nhập số điện thoại'},\n            ],\n          },\n        ]"}],attrs:{"placeholder":"Số điện thoại","initialValue":_vm.userInfo.phone},on:{"keyup":function($event){return _vm.check()}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }